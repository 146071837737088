import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedUserInfoComponent } from './logged-user-info.component';
import { MaterialSharedModule } from '../../material-shared.module';



@NgModule({
  declarations: [
    LoggedUserInfoComponent
  ],
  imports: [
    CommonModule,
    MaterialSharedModule
  ],
  exports: [
    LoggedUserInfoComponent
  ]
})
export class LoggedUserInfoModule { }
