// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'development',
  production: false,
  backendUrl: 'https://dev.api.tamis.app.br/api/',
  igestoAuthToken: 'igestoAuthToken',
  firebaseConfig: {
    apiKey: 'AIzaSyAt7DBe8Qlq0ISgeZNq4rF3SMoNzTv6wOc',
    authDomain: 'clarity-igesto.firebaseapp.com',
    projectId: 'clarity-igesto',
    storageBucket: 'clarity-igesto.appspot.com',
    messagingSenderId: '996053244722',
    appId: '1:996053244722:web:37f2c55abbc4f0e0e4952b',
  },
  cookiesExpiration: 60, // this is equivalent to 1 hour.
  templatesUrl: 'https://storage.googleapis.com/igesto-public-files/templates',
  importCreatePath: 'import-create',
  tenantId: 'development-d6hmg',
  version: "1.1.2.f2b5c8f9",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
