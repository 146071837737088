import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  SNACK_DEFAULT_DURATION,
  SNACK_DEFAULT_HORIZONTAL_POSITION,
  SNACK_DEFAULT_VERTICAL_POSITION,
} from '../shared/messages-constants';

@Injectable({
  providedIn: 'root',
})
export class SnackbarCustomService {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(msg: string, type: string, duration: number = SNACK_DEFAULT_DURATION ) {
    this._snackBar.open(msg, type, {
      duration: duration,
      verticalPosition: SNACK_DEFAULT_VERTICAL_POSITION,
      horizontalPosition: SNACK_DEFAULT_HORIZONTAL_POSITION,
      panelClass: [type],
    });
  }
}
