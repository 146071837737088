import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-logged-user-info',
  templateUrl: './logged-user-info.component.html',
  styleUrls: ['./logged-user-info.component.scss']
})
export class LoggedUserInfoComponent {
  @Output() onSignOut = new EventEmitter<any>();
  @Input() userName = ''
  @Input() institutionName = ''

  signOut(): void {
    this.onSignOut.emit(false);
  }
}
