
<div class="user-data">
  <!-- <div class="notifications-container">
    <mat-icon matBadgeColor="primary">notifications</mat-icon>
      <span class="cdk-visually-hidden">
        Notificações
      </span>
  </div> -->
  <mat-icon [matMenuTriggerFor]="userMenu" matBadgeColor="primary">account_circle</mat-icon>
  <mat-menu #userMenu="matMenu">
    <div class="logged-username"><mat-icon matBadgeColor="primary">account_circle</mat-icon>{{userName}}</div>
    <hr>
    <button mat-menu-item (click)="signOut()">Sair</button>
  </mat-menu>
</div>