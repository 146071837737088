import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import * as dayjs from 'dayjs';
import { GeneralConstants } from 'src/app/shared/util/constants/general-constants';

export function NotFutureDateValidator(
  control: UntypedFormControl
): ValidationErrors {
  if (dayjs(control.value, GeneralConstants.DAY_ISO_FORMAT).isAfter(dayjs())) {
    return { 'is-future-date': true };
  }
  return null;
}
