export function navRouterSwitch(path: string) {
  let chosedPath = '';
  switch (path) {
    case 'user': {
      chosedPath = '/app/patient';
      break;
    }
    case 'patient-visits': {
      chosedPath = '/app/patient/visits';
      break;
    }
    case 'patient': {
      chosedPath = '/app/health-member/patient-list';
      break;
    }
    case 'visits': {
      chosedPath = '/app/health-member/visits';
      break;
    }
    case 'member': {
      chosedPath = '/app/health-member/list';
      break;
    }
    case 'medicine': {
      chosedPath = '/app/medicine-prescription';
      break;
    }
    case 'form': {
      chosedPath = '/app/form/list';
      break;
    }
    case 'trial': {
      chosedPath = '/app/trial';
      break;
    }
    case 'institution': {
      chosedPath = '/app/institution/list';
      break;
    }
    case 'configuration': {
      chosedPath = '/app/configuration';
      break;
    }
    case 'memberConfigurations': {
      chosedPath = '/app/templates';
      break;
    }
    case 'adminConfigurations': {
      chosedPath = '/app/admin-configuration';
      break;
    }
    case 'carePlan': {
      chosedPath = '/app/care-plan';
      break;
    }
    default: {
      path = '';
      break;
    }
  }
  return chosedPath;
}
