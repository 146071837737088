import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService, ROLES } from 'src/app/auth/auth.service';
import { CookiesService } from 'src/app/auth/cookies.service';
import { SignService } from 'src/app/services/user-services/sign.service';
import { navRouterSwitch } from '../util/export-functions/nav-router-switch';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isLoading = false;
  roles = ROLES;

  userInactive: Subject<any> = new Subject();

  constructor(
    public authService: AuthService,
    private signService: SignService,
    private router: Router,
    private cookiesService: CookiesService
  ) { }

  navbarRouter(path: string) {
    return this.router.navigate([navRouterSwitch(path)]);
  }

  isNavbarItemActive(itemPath: string): boolean {
    return this.router.url.includes(itemPath);
  }

  goToDashboard() {
    if (this.authService.haveSetToken()) {
      window.open('https://dashfrontend-996053244722.southamerica-east1.run.app/', '_blank')
    }
  }

  async signOut(): Promise<void> {
    try {
      await this.signService.signOut();
      this.authService.signOut();
    } catch (e) {
      console.error(e);
    }
    await this.router.navigate(['signIn']);
  }

  get isNotLoggedIn(): boolean {
    return !this.router.url.includes('app');
  }

  get isPatientFullRegistered(): boolean {
    return (
      this.authService.checkUserRole(this.roles.PATIENT) &&
      this.authService.isEmailVerified()
    );
  }

  get userName(): string {
    return this.cookiesService.getCookie('userName');
  }

  get institutionName(): string {
    return this.cookiesService.getCookie('institutionName');
  }

  get userRole() {
    return this.authService.getUserRole();
  }
}
