import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfMaskModule } from '../directives/cpf-mask/cpf-mask.module';
import { HeaderComponent } from './header.component';
import { MaterialSharedModule } from '../material-shared.module';
import { LoggedUserInfoModule } from '../components/logged-user-info/logged-user-info.module';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    CpfMaskModule,
    LoggedUserInfoModule,
    MaterialSharedModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
