import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import {
  Auth,
  User,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { environment } from 'src/environments/environment';
import { UserData, UserLocalInfo } from '../models/user.model';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public auth: Auth;
  public user: User;
  public userObserver: EventEmitter<any> = new EventEmitter(true);
  parsedToken: any;
  userData: UserLocalInfo;

  constructor(
    private cookiesService: CookiesService
  ) {
    this.auth = getAuth(
      firebase.initializeApp({
        apiKey: environment.firebaseConfig.apiKey,
        authDomain: environment.firebaseConfig.authDomain,
        projectId: environment.firebaseConfig.projectId,
        storageBucket: environment.firebaseConfig.storageBucket,
        messagingSenderId: environment.firebaseConfig.messagingSenderId,
        appId: environment.firebaseConfig.appId,
      })
    );
    this.auth.languageCode = 'pt-BR';
    this.auth.tenantId = environment.tenantId;
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        this.userObserver.emit(this.user);
      } else {
        this.userObserver.emit(false);
      }
    });
  }

  signInWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(this.auth, email);
  };

  sendEmailVerification = (user = null) => {
    return sendEmailVerification(user ? user : this.user);
  };

  signOut() {
    this.cookiesService.clearAllCookies();
    window.location.reload();
    return;
  };

  getUserData(): UserData {
    return this.userData;
  }

  getUserUid(): string {
    const userUid = this.cookiesService.getCookie('uid');
    return userUid ?? this.getUserData().uid;
  }

  getUserRole(): string {
    const userRole = this.cookiesService.getCookie('role');
    return userRole ?? this.userData?.cod_perfil_membro_saude ?? 'notHaveRole';
  }

  async setUserData(userData: UserLocalInfo) {
    this.userData = userData;
    let isMember = false;
    if (userData?.cod_perfil_membro_saude != null) {
      isMember = true
    }
    await this.setLocalCookies(userData, isMember);
  }

  async setLocalCookies(userData: UserLocalInfo, isMember: boolean) {
    this.cookiesService.setCookie('uid', userData.cod_usuario, environment.cookiesExpiration);
    if (isMember) {
      this.cookiesService.setCookie('role', userData.cod_perfil_membro_saude, environment.cookiesExpiration);
      this.cookiesService.setCookie('institutionName', userData.institutionName, environment.cookiesExpiration);
      this.cookiesService.setCookie('userName', userData.nom_membro_equipe_saude, environment.cookiesExpiration);
      return;
    }
    this.cookiesService.setCookie('role', 'PATIENT', environment.cookiesExpiration);
    this.cookiesService.setCookie('isEmailVerified', String(userData.isEmailVerified), environment.cookiesExpiration);
    this.cookiesService.setCookie('userName', userData.nom_paciente, environment.cookiesExpiration);
  }

  async haveSetToken() {
    const token = this.cookiesService.getCookie('token')
    if (token) {
      window.localStorage.setItem('token', token)
      return true;
    }
    return false;
  }

  isEmailVerified(): boolean {
    const isVerified = this.cookiesService.getCookie('isEmailVerified');
    return isVerified == 'true' ? true : false;
  }

  checkUserRole(expectedRole: string): boolean {
    const userRole = this.getUserRole();
    return userRole === expectedRole;
  }

  async refreshUser(): Promise<void> {
    await this.auth.currentUser?.reload();
  }

  async buildAPIAuthHeader(contentType = null): Promise<HttpHeaders> {
    const token = this.cookiesService.getCookie('token');
    if (token != null) {
      if (contentType) {
        return new HttpHeaders().append('authorization', `Bearer ${token}`);
      }
      return new HttpHeaders()
        .append('authorization', `Bearer ${token}`)
        .append('Content-Type', 'application/json');
    }
    this.signOut();
    return null
  }
}

export const ROLES = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  PATIENT: 'PATIENT',
  IGESTO_ADMIN: 'IGESTO_ADMIN',
};
