<div class="trial-agreement-container">
  <h1><strong>TAMIS e OMRON</strong> - Adendo para o Paciente</h1>
  <ul>
    <li>
      <h2>Identificação da Terceira Parte</h2>
      <p>
        O presente acordo inclui, como terceira parte, <strong>CLARITY HEALTH</strong>, com sede em
        Rua Emerson José Moreira, 1710, Chácara Primavera, na Cidade de Campinas, Estado de São Paulo,
        CEP: 13.087-441, inscrita no CNPJ 37.165.808/0001-39, doravante denominada “Terceira Parte”.
      </p>
    </li>
    <li>

      <h2>Função da Terceira Parte</h2>
      <p>
        A Terceira Parte irá capturar as medidas de pressão arterial coletadas pelo equipamento da <strong>OMRON</strong>
        específico para essa finalidade e armazenado em nuvem, com o objetivo de dar suporte à decisão
        clínica impulsionada por Inteligência Artificial, ajudando os profissionais de saúde a oferecer
        atendimento de alta qualidade e gerenciar a saúde de seus pacientes com recursos exclusivos.
      </p>
    </li>
    <li>

      <h2>Obrigações da Terceira Parte</h2>
      <p>
        A Terceira Parte deverá realizar suas atividades em conformidade com as especificações acordadas
        entre as Partes e seguindo normas e leis pertinentes, como: a Lei Geral de Proteção de Dados
        Pessoais - LGPD - (LEI Nº 13.709); lei que dispõe sobre a digitalização e a utilização de
        sistemas informatizados para prontuários médicos (LEI Nº 13.787), além das resoluções do
        Conselho Federal de Medicina, entre outras normas e regulações relacionadas ao tema de
        segurança e privacidade de dados.
      </p>
    </li>
    <li>

      <h2>Responsabilidade da Terceira Parte</h2>
      <p>
        A Terceira Parte será responsável por manter o sigilo sobre todas as informações confidenciais
        obtidas durante a prestação dos serviços, conforme descrito no item C, e que estejam dentro de
        sua gestão de sistemas.
      </p>
    </li>
  </ul>
</div>