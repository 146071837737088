import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialSharedModule } from './material-shared.module';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ConfirmDialog } from './components/confirm-dialog/confirm-dialog.component';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { ImportDialog } from './components/import-dialog/import-dialog.component';
import { LoadImportTemplateDialog } from './components/load-import-template-dialog/load-import-template-dialog.component';
import { PrivacyPoliceComponent } from './privacy-police/privacy-police.component';
import { CpfMaskPipe } from './pipes/cpf-maks.pipe';

@NgModule({
  declarations: [
    ConfirmDialog,
    ImportDialog,
    LoadImportTemplateDialog,
    SafeHtmlPipe,
    PrivacyPoliceComponent,
    CpfMaskPipe,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    MaterialSharedModule,
    FormlyMaterialModule,
  ],
  exports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    MaterialSharedModule,
    FormlyMaterialModule,
    CpfMaskPipe,
  ],
})
export class AngularCoreSharedModule {}
