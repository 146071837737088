export class GeneralConstants {
  static MIN_SOCIAL_SECURITY_LENGTH = 9;
  static MAX_SOCIAL_SECURITY_LENGTH = 10;

  static MIN_PHYSICAL_PERSON_NUMBER_LENGTH = 11;
  static MAX_PHYSICAL_PERSON_NUMBER_LENGTH = 12;

  static DEFAULT_DISPLAY_DATE = 'DD/MM/YYYY HH:mm:ss';
  static DEFAULT_DISPLAY_ONLY_DATE = 'DD/MM/YYYY';

  static IMPORT_DIALOG_TITLE =
    'Clique em carregar arquivo ou baixe um template para preenchimento manual';

  static DAY_ISO_FORMAT = 'YYYY-MM-DD';
}
