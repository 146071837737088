<mat-toolbar class="tamis-header" color="primary"
  *ngIf="userRole != null && userRole !== roles.PATIENT && !isNotLoggedIn">
  <img src="../../../assets/images/tamis_logo.png" class="logo-header">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="drop-menu">
    <mat-icon fontSet="fa" fontIcon="fa-bars"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <section *ngIf="userRole === roles.MEMBER" id="member">
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('patient')}"
        (click)="navbarRouter('patient')">Pacientes</button>
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('visits')}"
        (click)="navbarRouter('visits')">Visitas</button>
      <button mat-menu-item
        [ngClass]="{'active-nav-item': isNavbarItemActive('health-member/list') || isNavbarItemActive('health-member/detail') || isNavbarItemActive('health-member/register')}"
        (click)="navbarRouter('member')">Membros</button>
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('medicine')}"
        (click)="navbarRouter('medicine')">Prescrições
        Médicas</button>
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('form')}"
        (click)="navbarRouter('form')">Questionários</button>
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('care-plan')}"
        (click)="navbarRouter('carePlan')">Plano de cuidados</button>
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('dashboard')}"
        (click)="navbarRouter('dashboard')">Dashboard</button>
      <!-- to be switched back
        <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('trial')}"
          (click)="navbarRouter('trial')">Trial</button>
        <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('register-template')}"
          (click)="navbarRouter('memberConfigurations')">Configurações</button>
            eventually -->
    </section>

    <button mat-menu-item *ngIf="userRole !== roles.MEMBER"
      [ngClass]="{'active-nav-item': isNavbarItemActive('health-member/list') || isNavbarItemActive('health-member/detail') || isNavbarItemActive('health-member/register')}"
      (click)="navbarRouter('member')">Membros</button>

    <section *ngIf="userRole === roles.ADMIN" id="admin">
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('admin-configuration')}"
        (click)="navbarRouter('adminConfigurations')">Configuração</button>
    </section>

    <section *ngIf="userRole === roles.IGESTO_ADMIN" id="igesto-admin">
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('institution')}"
        (click)="navbarRouter('institution')">Instituição</button>
      <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('configuration')}"
        (click)="navbarRouter('configuration')">Configuração</button>
    </section>
  </mat-menu>

  <section *ngIf="userRole === roles.MEMBER" class="header-nav-itens member">
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('patient')}" (click)="navbarRouter('patient')"
      class="navbar-item">Pacientes</button>
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('visits')}" (click)="navbarRouter('visits')"
      class="navbar-item">Visitas</button>
    <button mat-button
      [ngClass]="{'active-nav-item': isNavbarItemActive('health-member/list') || isNavbarItemActive('health-member/detail') || isNavbarItemActive('health-member/register')}"
      (click)="navbarRouter('member')" class="navbar-item">Membros</button>
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('medicine')}"
      (click)="navbarRouter('medicine')" class="navbar-item">Prescrições Médicas</button>
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('form')}" (click)="navbarRouter('form')"
      class="navbar-item">Questionários</button>
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('care-plan')}"
      (click)="navbarRouter('carePlan')" class="navbar-item">Plano de cuidados</button>
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('dashboard')}" (click)="navbarRouter('dashboard')"
      class="navbar-item">Dashboard</button>
    <!-- to be switched back
      <button mat-button
        [ngClass]="{'active-nav-item': isNavbarItemActive('trial')}"
        (click)="navbarRouter('trial')" class="navbar-item">Trial</button>
      <button mat-button
        [ngClass]="{'active-nav-item': isNavbarItemActive('register-template')}"
        (click)="navbarRouter('memberConfigurations')" class="navbar-item">Configurações</button>
          eventually -->
  </section>

  <button mat-button *ngIf="userRole !== roles.MEMBER"
    [ngClass]="{'active-nav-item': isNavbarItemActive('health-member/list') || isNavbarItemActive('health-member/detail') || isNavbarItemActive('health-member/register')}"
    (click)="navbarRouter('member')" class="navbar-item">Membros</button>

  <section *ngIf="userRole === roles.ADMIN" class="header-nav-itens admin">
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('admin-configuration')}"
      (click)="navbarRouter('adminConfigurations')" class="navbar-item">Configurações</button>
  </section>

  <section *ngIf="userRole === roles.IGESTO_ADMIN" class="header-nav-itens igesto-admin">
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('institution')}"
      (click)="navbarRouter('institution')" class="navbar-item">Instituição</button>
    <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('configuration')}"
      (click)="navbarRouter('configuration')" class="navbar-item">Configuração</button>
  </section>

  <div class="end-header">
    <mat-spinner *ngIf="isLoading" class="spinner-center" diameter="50" color="white" mode="indeterminate">
    </mat-spinner>
    <div class="institution-label">{{institutionName}}</div>
    <app-logged-user-info *ngIf="!isLoading" (onSignOut)="signOut()" [userName]="userName"
      [institutionName]="institutionName"></app-logged-user-info>
  </div>
</mat-toolbar>

<!-- Patient menu -->
<mat-toolbar class="tamis-header" color="primary" *ngIf="userRole === roles.PATIENT && !isNotLoggedIn">
  <img src="../../../assets/images/tamis_logo.png" class="logo-header">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="drop-menu">
    <mat-icon fontSet="fa" fontIcon="fa-bars"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [ngClass]="{'active-nav-item': isNavbarItemActive('user')}" (click)="navbarRouter('user')"
      *ngIf="isPatientFullRegistered">Dados Cadastrais</button>
    <button mat-menu-item *ngIf="isPatientFullRegistered" [ngClass]="{'active-nav-item': isNavbarItemActive('patient')}"
      (click)="navbarRouter('patient-visits')">Visitas</button>
  </mat-menu>
  <button mat-button [ngClass]="{'active-nav-item': isNavbarItemActive('user')}" (click)="navbarRouter('user')"
    class="navbar-item" *ngIf="isPatientFullRegistered">Dados
    Cadastrais</button>
  <button mat-button *ngIf="isPatientFullRegistered" (click)="navbarRouter('patient-visits')"
    class="navbar-item">Visitas</button>
  <div class="end-header">
    <mat-spinner *ngIf="isLoading" class="spinner-center" diameter="50" color="white" mode="indeterminate">
    </mat-spinner>
    <div class="institution-label" *ngIf="institutionName != null">{{institutionName}}</div>
    <app-logged-user-info *ngIf="!isLoading" (onSignOut)="signOut()" [userName]="userName"></app-logged-user-info>
  </div>
</mat-toolbar>
<!-- Patient menu -->