import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CpfMaskDirective } from './cpf-mask.directive';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [CpfMaskDirective],
  exports: [CpfMaskDirective],
})
export class CpfMaskModule {}
