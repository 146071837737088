import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardService } from './services/guard-services/admin-guard.service';
import { FormGuardService } from './services/guard-services/form-guard.service';
import { HealthMemberGuardService } from './services/guard-services/health-member-guard.service';
import { IgestoAdminGuardService } from './services/guard-services/igesto-admin-guard.service';
import { PatientGuardService } from './services/guard-services/patient-guard.service';
import { PrivacyPoliceComponent } from './shared/privacy-police/privacy-police.component';
import { TrialAgreementComponent } from './shared/trial-agreement/trial-agreement.component';
import { MemberGuardService } from './services/guard-services/member-guard.service';
import { LoggedGuardService } from './services/guard-services/logged-guard.service';

const routes: Routes = [
  {
    path: 'signIn',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/tamis-access/tamis-login/tamis-login.module').then(
        (m) => m.TamisLoginModule
      ),
  },
  {
    path: 'signUp',
    loadChildren: () =>
      import('./pages/tamis-access/tamis-register/tamis-register.module').then(
        (m) => m.TamisRegisterModule
      ),
  },
  {
    path: 'user-action',
    loadChildren: () =>
      import('./pages/user-actions/user-actions.module').then(
        (m) => m.UserActionsModule
      ),
  },
  {
    path: 'planosCuidados',
    loadChildren: () =>
      import('./pages/form-fill-management/form-fill-management.module').then(
        (m) => m.FormFillManagementModule
      ),
  },
  {
    path: 'privacy',
    component: PrivacyPoliceComponent,
  },
  {
    path: 'trial_agreement',
    component: TrialAgreementComponent,
  },
  {
    path: 'app',
    children: [
      {
        path: 'patient',
        canActivate: [PatientGuardService],
        loadChildren: () =>
          import('./pages/patient/patient.module').then((m) => m.PatientModule),
      },
      {
        path: 'first-access',
        canActivate: [LoggedGuardService],
        loadChildren: () =>
          import(
            './pages/tamis-access/tamis-first-access/tamis-first-access.module'
          ).then((m) => m.TamisFirstAccessModule),
      },
      {
        path: 'health-member',
        canActivate: [HealthMemberGuardService],
        loadChildren: () =>
          import('./pages/health-member/health-member.module').then(
            (m) => m.HealthMemberModule
          ),
      },
      {
        path: 'institution',
        canActivate: [IgestoAdminGuardService],
        loadChildren: () =>
          import('./pages/institution/institution.module').then(
            (m) => m.InstitutionModule
          ),
      },
      {
        path: 'medicine-prescription',
        canActivate: [MemberGuardService],
        loadChildren: () =>
          import(
            './pages/medicine-prescription/medicine-prescription.module'
          ).then((m) => m.MedicinePrescriptionModule),
      },
      {
        path: 'form',
        canActivate: [MemberGuardService],
        loadChildren: () =>
          import('./pages/form/form.module').then((m) => m.FormModule),
      },
      {
        path: 'form',
        children: [
          {
            path: '',
            canActivate: [FormGuardService],
            loadChildren: () =>
              import('./pages/form/form.module').then((m) => m.FormModule),
          },
        ],
      },
      {
        path: '',
        canActivate: [IgestoAdminGuardService],
        loadChildren: () =>
          import(
            './pages/configuration-management/configuration-management.module'
          ).then((m) => m.ConfigurationManagementModule),
      },
      {
        path: '',
        canActivate: [HealthMemberGuardService],
        loadChildren: () =>
          import('./pages/template-management/template-management.module').then(
            (m) => m.TemplateManagementModule
          ),
      },
      {
        path: '',
        canActivate: [MemberGuardService],
        loadChildren: () =>
          import(
            './pages/care-plan-management/care-plan-management.module'
          ).then((m) => m.CarePlanManagementModule),
      },
      {
        path: '',
        canActivate: [AdminGuardService],
        loadChildren: () =>
          import(
            './pages/admin-configuration-management/admin-configuration-management.module'
          ).then((m) => m.AdminConfigurationManagementModule),
      },
      {
        path: '',
        canActivate: [HealthMemberGuardService],
        loadChildren: () =>
          import('./pages/trial-management/trial-management.module').then(
            (m) => m.TrialManagementModule
          ),
      },
    ],
  },
  {
    path: '',
    redirectTo: '/signIn',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/signIn',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
