<div class="privacy-content">
  <h1>Política de Privacidade</h1>
  <p>
    Esse documento apresenta a Política de Privacidade do Sistema TAMIS, com a
    descrição e formas de como os dados coletados para a execução dos serviços
    médicos tratados. As definições apresentadas estão em conformidade com as
    Leis que regem o setor médico, além de estarem em cumprimento com a Lei nº
    13.709 - Lei Geral de Proteção de Dados (LGPD).
  </p>
  <p>
    Esse documento se refere como “Titular” àqueles que têm seus dados coletados
    pelo sistema e “Controladora” denominando a empresa TAMIS, que tomará
    decisões referentes ao tratamento de seus dados pessoais necessários à
    execução dos serviços, envolvendo operações como as que se referem a coleta,
    produção, recepção, classificação, utilização, acesso, reprodução,
    transmissão, distribuição, processamento, arquivamento, armazenamento,
    eliminação, avaliação ou controle da informação, modificação, comunicação,
    transferência, difusão ou extração.
  </p>

  <h2>Quais dados do Titular são coletados pelo sistema TAMIS?</h2>
  <p>
    Dados pessoais: informação relacionada a pessoa natural identificada ou
    identificável. Como exemplo, são coletados dados pessoais do Titular:
  </p>
  <ul>
    <li>Nome completo</li>
    <li>RG e CPF</li>
    <li>Data de nascimento</li>
    <li>Sexo</li>
    <li>Número de telefone</li>
    <li>Endereço de e-mail</li>
    <li>Nome da Mãe</li>
  </ul>
  <p>
    Dados pessoais sensíveis: dado pessoal sobre origem racial ou étnica,
    convicção religiosa, opinião política, filiação a sindicato ou a organização
    de caráter religioso, filosófico ou político, dado referente à saúde ou à
    vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa
    natural. Como exemplo, são coletados dados pessoais e sensíveis do Titular
    como:
  </p>

  <h2>Para qual finalidade os dados do Titular são utilizados?</h2>
  <p>
    O tratamento dos dados pessoais listados neste termo tem as seguintes
    finalidades:
  </p>
  <ul>
    <li>
      Possibilitar que a Controladora identifique e entre em contato com o
      Titular para fins para a devida prestação do serviço médico.
    </li>
    <li>
      Possibilitar que a Controladora forneça ao Titular os serviços dispostos
      no contrato do Serviço de Saúde.
    </li>
    <li>
      Permitir que a Controladora alimente seu banco interno de cadastro de
      pacientes, para fins de conhecimento científico e para a devida prestação
      do serviço médico.
    </li>
  </ul>

  <h2>Os dados do Titular são compartilhados? Com qual finalidade?</h2>
  <p>
    A Controladora poderá compartilhar os dados do Titular com outros agentes de
    tratamento de dados caso seja necessário para as finalidades listadas neste
    termo, observados os princípios e as garantias estabelecidas pela Lei nº
    13.709.
  </p>

  <h2>Como é feita a segurança dos dados do Titular?</h2>
  <p>
    A Controladora adota todas as medidas de segurança, técnicas e
    administrativas aptas a proteger os dados pessoais de acessos não
    autorizados e de situações acidentais ou ilícitas de destruição, perda,
    alteração, comunicação ou qualquer forma de tratamento inadequado ou
    ilícito. Em conformidade ao Art. 48 da Lei nº 13.709, a Controladora
    comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a
    ocorrência de incidente de segurança que possa acarretar risco ou dano
    relevante ao Titular.
  </p>

  <h2>Como será o tratamento e a eliminação dos dados do Titular?</h2>
  <p>
    A Controladora poderá manter e tratar os dados pessoais do Titular durante
    todo o período em que forem pertinentes ao alcance das finalidades listadas
    neste termo e pelo prazo legal definido nas Leis LGPD e àquelas pertinentes
    ao tratamento médico. O Titular tem autonomia para solicitar a exclusão de
    seus dados pessoais, mediante solicitação por escrito ou verbal.
  </p>

  <h2>Quais são os direitos do Titular, em relação aos seus dados?</h2>
  <p>
    O Titular tem direito a obter da Controladora, em relação aos dados por ela
    tratados, a qualquer momento e mediante requisição escrita ou verbal:
  </p>
  <ul>
    <li>Confirmação da existência de tratamento;</li>
    <li>Acesso aos dados;</li>
    <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
    <li>
      Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos
      ou tratados em desconformidade com o disposto na Lei nº 13.709;
    </li>
    <li>
      Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante
      requisição expressa, de acordo com a regulamentação da autoridade
      nacional, observados os segredos comercial e industrial;
    </li>
    <li>
      Eliminação dos dados pessoais tratados com o consentimento do Titular,
      exceto nas hipóteses previstas no Art. 16 da Lei nº 13.709;
    </li>
    <li>
      Informação das entidades públicas e privadas com as quais a Controladora
      realizou uso compartilhado de dados;
    </li>
    <li>
      Informação sobre a possibilidade de não fornecer consentimento e sobre as
      consequências da negativa;
    </li>
    <li>
      Revogação do consentimento, nos termos do § 5º do Art. 8º da Lei nº
      13.709.
    </li>
  </ul>

  <h2>
    O Titular dos dados tem direito a revogar o termo de consentimento dado?
  </h2>
  <p>
    O termo de consentimento, instrumento adotado para registrar a manifestação
    livre, informada e inequívoca do Titular quanto ao consentimento para o
    tratamento de seus dados pessoais, poderá ser revogado pelo Titular, a
    qualquer momento, mediante opção no sistema, ficando ciente de que poderá
    ser inviável à Controladora continuar o fornecimento dos serviços a partir
    do não-consentimento e/ou a eliminação dos dados pessoais.
  </p>

  <h2>Canais de atendimento:</h2>
  <p>
    Em caso de dúvidas a respeito desta Política de Privacidade do Sistema
    TAMIS, entrar em contato com o endereço de email:
    <a href="mailto:suporte@clarityhealth.com.br"
      >suporte&#64;clarityhealth.com.br</a
    >.
  </p>
</div>
